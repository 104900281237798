import React, { useState } from "react";
import PrimaryButton from "../../Components/UI/PrimaryButton/PrimaryButton";
import ScrollCarousel from "scroll-carousel-react";
import "./Landing.scss";

import {
  Asthma,
  BloodTest,
  Bronchitis,
  Diarrhea,
  Fever,
  GORD,
  HeartBurn,
  ImagingRequest,
  MentalHealth,
  ReferralLetters,
  Reflux,
  RepeatMedication,
  STI,
  SkinProblem,
  UTI,
  Vomiting,
  Xray,
  anxiety,
  arrow,
  busy,
  calender,
  clock,
  cough,
  doctor,
  fever,
  lightIcon,
  location,
  main,
  mainImg,
  mainImgOne,
  medicalCertificate,
  migraine,
  person,
  persons,
  soreThroat,
  time,
} from "../../Assets";
import { ReactSVG } from "react-svg";
import { Link, useNavigate } from "react-router-dom";
import Testimonials from "../../Components/Testimonials/Testimonial";
import Accordion from "../../Components/FAQ/Accordion";
import About from "../about/About";
import Contact from "../contact/Contact";
const megaButton = [
  {
    icon: person,
    heading: "Individual Consultation",
    description: "For myself",
  },
  {
    icon: persons,
    heading: "Kids Consultation",
    description: "For my child",
  },
  {
    icon: persons,
    heading: "Couple Consultation",
    description: "For my spouse",
  },
];
const diseases = [
  {
    img: UTI,
    heading: "UrinaryTract Infection",
  },
  {
    img: soreThroat,
    heading: "Sore Throat",
  },
  {
    img: Fever,
    heading: "Fever",
  },
  {
    img: medicalCertificate,
    heading: "Medical certificates",
  },
  {
    img: ReferralLetters,
    heading: "Referral letters",
  },
  {
    img: RepeatMedication,
    heading: "Repeat medication",
  },
  {
    img: Xray,
    heading: "X-ray Requests",
  },
  {
    img: ImagingRequest,
    heading: "Imaging Requests",
  },
  {
    img: BloodTest,
    heading: "Blood Tests",
  },
  {
    img: STI,
    heading: "Sexual Health Test",
  },
  {
    img: cough,
    heading: "Cough",
  },
  {
    img: Vomiting,
    heading: "Vomiting",
  },
  {
    img: Diarrhea,
    heading: "Diarrhea",
  },
  {
    img: Reflux,
    heading: "Reflux (GORD)",
  },
  {
    img: HeartBurn,
    heading: "Heart Burn",
  },
  {
    img: MentalHealth,
    heading: "Mental Health Discussion",
  },
  {
    img: SkinProblem,
    heading: "Skin Problems",
  },
  {
    img: Asthma,
    heading: "Asthma",
  },
  {
    img: Bronchitis,
    heading: "Bronchitis",
  },
];

const consultSteps = [
  {
    num: 1,
    heading: "Book a consultation online",
    description: "Skip the waiting room and save time",
  },
  {
    num: 2,
    heading: "Speak to a Doctor",
    description:
      "Discuss your symptoms or medical issues in a secure, confidential manner via phone call or video call.",
  },
  {
    num: 3,
    heading: "Finish",
    description:
      "Get prescriptions, medical certificates, blood tests or advice according to your healthcare needs",
  },
];

const chooseUsSteps = [
  {
    img: calender,
    heading: "Doctors available 24/7 including public holidays",
  },
  {
    img: location,
    heading: "Serving the whole of Australia",
  },
  {
    img: time,
    heading: "Get E-scripts by text to your phone within minutes",
  },
  {
    img: busy,
    heading: "Fit in around your busy schedule",
  },
];
const Landing = ({ setIsNav }) => {
  // const handleConsultClick = () => {
  //   window.location.href = "/booking";
  // };

  const navigate = useNavigate();
  const [showAll, setShowAll] = useState(false); // Toggle for showing all disease cards

  const handleShowAll = () => {
    setShowAll(true); // Show all disease cards
  };

  const handleShowLess = () => {
    setShowAll(false); // Show only 6 disease cards
  };
  // Handle click on each card to navigate to booking page
  const handleCardClick = () => {
    navigate("/booking");
  };

  return (
    <div className="landing-container" onClick={() => setIsNav(false)}>
      <div className="main">
        {/* <h5>
          Are you having <span> fever? </span>
        </h5> */}
        <div className="heading">
          <h1>
            Consult with Qualified Doctors by Telephone or Video call
            {/* <ReactSVG src={lightIcon} /> */}
          </h1>
          <p>
            <strong>Skip the waiting queues!</strong> Speak to Australian
            registered Doctors from anywhere in Australia, wherever you are.
          </p>
          <PrimaryButton
            className="button"
            onClick={() => navigate("/booking")}
          >
            Speak to a Doctor Now
          </PrimaryButton>
        </div>

        <img src={mainImg} alt="img-main" className="main-img" />
      </div>
      {/* <div className="button-heading">
        <h6>Service we could provide</h6>
        <div className="mega-button">
          {megaButton.map((el) => {
            return (
              <div className="button-array">
                <div className="icon">
                  <ReactSVG src={el.icon} />
                </div>
                <h2>{el.heading}</h2>
                <div className="arrow-button">
                  <span onClick={() => navigate("/booking")}>
                    {el.description}
                  </span>
                  <ReactSVG src={arrow} />
                </div>
              </div>
            );
          })}
        </div>
      </div> */}
      <div className="video-session">
        <ReactSVG src={doctor} className="doctor" />
        <div className="description">
          <h2>Accessible Healthcare, Anywere in Australia </h2>
          <p>
            Consult with licensed and experienced Doctors, from the comfort of
            your home or to fit in with your busy life schedule. Our
            consultations are secure and confidential, allowing you to access
            healthcare whenever you need. Whether you need a prescription,
            medical certificate, expert advice or anything else, our online
            Doctors are here to help.
          </p>
          <div className="session-button" onClick={() => navigate("/booking")}>
            <div className="button-icon">
              <ReactSVG src={clock} />
              {/* <h3>Round the clock healthcare service</h3> */}
              <h3>Speak to a Doctor Now</h3>
            </div>
            <ReactSVG src={arrow} />
          </div>
        </div>
      </div>
      <div id="consult-steps">
        <h1 id="navigate">How does it work?</h1>
        <p className="description">As easy as 1...2...3..</p>
        <div className="steps-cards">
          {consultSteps.map((el) => {
            return (
              <div className="steps-array">
                <span>{el.num}</span>
                <div className="headiang-des">
                  <h2>{el.heading}</h2>
                  <p>{el.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="diseases">
        <h1>Examples of conditions that Medidoc Doctors can help with</h1>
        <div className="disease-cards">
          <div className="disease-flex">
            {diseases
              .slice(0, showAll ? diseases.length : 6)
              .map((el, index) => (
                <div
                  className="disease-array"
                  key={index}
                  onClick={handleCardClick}
                >
                  <img src={el.img} className="diseaseImg" alt={el.heading} />
                  <h2>{el.heading}</h2>
                </div>
              ))}
          </div>
        </div>
        <div className="more-button">
          {!showAll ? (
            <span onClick={handleShowAll}>
              See all
              <ReactSVG src={arrow} />
            </span>
          ) : (
            <span onClick={handleShowLess}>
              Show less
              <ReactSVG src={arrow} />
            </span>
          )}
        </div>
      </div>
      <div className="bottom-button">
        <h2>Speak to a Doctor right away</h2>
        <a href="/booking">
          <PrimaryButton className="button">Get Started</PrimaryButton>
        </a>
      </div>
      <div id="consult-steps2">
        <h1 id="navigate">24/7 Anywhere in Australia</h1>
        <p className="description">
          Fast, Affordable, Quality healthcare from the comfort of your home
        </p>

        <div className="steps-cards2">
          {chooseUsSteps.map((el) => {
            return (
              <div className="steps-array2">
                <span>
                  <img src={el.img} alt="" />
                </span>
                <div className="headiang-des2">
                  <h3 style={{ fontWeight: "400", fontSize: "16px" }}>
                    {el.heading}
                  </h3>
                  {/* <p>{el.description}</p> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <About />
      <Testimonials />
      <Accordion />
      <Contact />
    </div>
  );
};

export default Landing;
