import { Route, Routes, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import Landing from "./Pages/Landing/Landing";
import BookingIframe from "./Pages/iFrames/BookingIframe";
import Contact from "./Pages/contact/Contact";
import About from "./Pages/about/About";
import DoctorProfile from "./Pages/iFrames/DoctorProfile";
import Accordion from "./Components/FAQ/Accordion";
import Testimonials from "./Components/Testimonials/Testimonial";
import Diseases from "./Components/Diseases/Diseases";
import TermsAndConditions from "./Pages/termsAndConditions/TermsAndConditions";
import Privacypolicy from "./Pages/Privacypolicy/Privacypolicy";

function App() {
  const [isNav, setIsNav] = useState(false);

  // function to get exact position from top during the navigation process.
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="app-container">
      <Header isNav={isNav} setIsNav={setIsNav} />
      <div className={isNav ? "drawer" : ""}>
        <Routes>
          <Route
            path="/*"
            element={<Landing isNav={isNav} setIsNav={setIsNav} />}
          />
          <Route path="/booking" element={<BookingIframe />} />
          <Route path="/profile" element={<DoctorProfile />} />
          <Route path="/diseases" element={<Diseases />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<Privacypolicy />} />
        </Routes>
        <Footer setIsNav={setIsNav} isNav={isNav} />
      </div>
    </div>
  );
}

export default App;
