import React from "react";
import "./Footer.scss";
import { ReactSVG } from "react-svg";
import { facebook, footerLogo, twitter, insta, utube } from "../../Assets";
import { useNavigate } from "react-router-dom";

const Footer = ({ setIsNav }) => {
  const navigate = useNavigate();
  const today = new Date();
  const year = today.getFullYear();

  return (
    <div className="footer-container" onClick={() => setIsNav(false)}>
      <div className="footer-content">
        <div className="logo-description">
          <div className="logo">
            <ReactSVG src={footerLogo} />
          </div>
          <p className="description">
            Medidoc provides high-quality telehealth consultations with
            experienced, fully licensed Australian doctors. From general health
            advice to prescriptions and referrals, we offer convenient, secure,
            and expert care - delivered to you wherever you are. Your health,
            your way, with doctors you can trust.
          </p>
          <p className="description2">
            In case of emergency or for urgent medical attention, please call
            000
          </p>
        </div>
        <div className="listed-social">
          <div className="listed-items">
            <h3>Services We Provide</h3>
            <ul>
              <li onClick={() => navigate("/booking")}>
                Online Doctor Consultations
              </li>
              <li onClick={() => navigate("/booking")}>Online Prescriptions</li>
              <li onClick={() => navigate("/booking")}>Medical Certificates</li>
              <li onClick={() => navigate("/booking")}>Blood Test Requests</li>
              <li onClick={() => navigate("/booking")}>Imaging Requests</li>
              <li onClick={() => navigate("/booking")}>Specialist Referrals</li>
            </ul>
          </div>
          {/* <div className="listed-items">
            <h3>Services We Provide</h3>
            <ul>
              <li onClick={() => navigate("/booking")}>
                Online Doctor Consultations
              </li>
              <li onClick={() => navigate("/booking")}>Online Prescriptions</li>
              <li onClick={() => navigate("/booking")}>Medical Certificates</li>
              <li onClick={() => navigate("/booking")}>Blood Test Requests</li>
              <li onClick={() => navigate("/booking")}>Imaging Requests</li>
              <li onClick={() => navigate("/booking")}>Specialist Referrals</li>
            </ul>
          </div> */}
          <div className="social-icons">
            <h3>Resources</h3>
            <div className="icons">
              <a
                href="https://www.youtube.com/@Medidoc-aus"
                target="_blank"
                className="icon"
              >
                <ReactSVG src={utube} />
              </a>
              <a
                href="https://www.facebook.com/share/199NP22syk/?mibextid=wwXIfr"
                target="_blank"
                className="icon"
              >
                <ReactSVG src={facebook} />
              </a>
              <a
                href="https://x.com/medidocaus"
                target="_blank"
                className="icon"
              >
                <ReactSVG src={twitter} />
              </a>
              <a
                href="https://www.instagram.com/medidoc_aus?igsh=azF2dGkwZnBycGxw&utm_source=qr"
                target="_blank"
                className="icon"
              >
                <ReactSVG src={insta} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className="rights">
        Copyright © Medidoc {year}.{" "}
        <div className="bottom-text">
          <span onClick={() => navigate("/terms")}>Terms of Service</span>
          <span onClick={() => navigate("/privacy")}>Privacy policy</span>
        </div>
      </p>
    </div>
  );
};

export default Footer;
