import React from "react";

const BookingIframe = () => {
  return (
    <>
      {/* <iframe
        src="https://www.halaxy.com/book/widget/gp-general-practitioner/dr-luqmaan-malik/1252651/986351"
        style={{ border: 0, width: "100%", height: "100vh" }}
      ></iframe> */}
      <iframe
        src="https://www.halaxy.com/book/widget/medid/location/986351"
        style={{
          border: "0",
          width: "100%",
          height: "100vh",
          marginTop: "114px",
        }}
      ></iframe>
    </>
  );
};

export default BookingIframe;
